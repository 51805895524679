export default {
    data() {
        return {
            dialog: false,
            progress: false,
            duplicatedLinks: [],
            selected: [],
            templateType: '',
            mailer: '',
            total: 0,
            totalAmount: 0.00,
            isLoading: false,
            isSendMailToAll: false,
            isNewsletterBtnLoading: false,
            showMailConfirm: false,
            showDeleteConfirm: false,
            showRemoveConfirm: false,
            showDeleteForeverConfirm: false,
            showConfirm: false,
            showConfirmMoz: false,
            currentSelectedItem: null,
            showFilesPopup: false,
            showLinkPopup: false,
            showCounterLinkPopup: false,
            showManualPaymentPopup: false,
            showRestoreConfirm: false,
            showResendMailConfirm: false,
            showReminderMailConfirm: false,
            showResetInvoiceConfirm: false,
            showResetWPAccess: false,
            showRepublishConfirm: false,
            showRepublishMailConfirm: false,
            showAfterReminderMailConfirm: false,
            showDeleteWithoutPostConfirm: false,
            showRefundConfirm: false,
            showEmailTimeliner: false,
            startDate: '',
            endDate: '',
            searchUser: '',
            paymentStatus: '',
            payMethod: '',
            paymentEmail: '',
            contactMail: '',
            billingType: '',
            payStatusOrderList: [
                {name: 'Unpaid', id: 100},
                {name: 'Pending', id: 300},
                {name: 'Republish', id: 600},
                {name: 'Refund', id: 400},
                {name: 'Prepaid', id: 700},
                {name: 'Paid', id: 200},
                {name: 'Removed', id: 900},
                {name: 'Deleted', id: 500},
                {name: 'New', id: 5000},
            ],
            billingTypes: [
                {name: 'Guest Posting on', id: 'Guest Posting on'},
                {name: 'Link Insertion on', id: 'Link Insertion on'},
                {name: 'Home Page Link Insertion on', id: 'Home Page Link Insertion on'},
                {name: 'Article Writing and Publishing on', id: 'Article Writing and Publishing on'}
            ],
            payStatus: [
                {name: 'Unpaid', id: 100},
                {name: 'Paid', id: 200},
                {name: 'Prepaid', id: 700},
                {name: 'Deleted', id: 500}
            ],
            shantoitPayStatus: [
                {name: 'Unpaid', id: 100},
                {name: 'Paid', id: 200},
            ],
            paymentEmails: [
                {name: 'Payment Mail', id: 'paymentMail'},
                {name: 'Update Mail', id: 'updateMail'},
                {name: 'Removal Mail', id: 'removalMail'},
                {name: 'After Removal Mail', id: 'afterRemovalMail'},
            ],
            mailTemplates: [
                {name: 'Resend Payment Email', id: '100'},
                {name: 'Post Removal Payment Email', id: '200'},
                {name: 'After Post Removal Payment Email', id: '400'},
            ],
            contacts: [
                {name: 'Editor', id: 'Editor'},
            ],
            mailSenders: [
                {name: 'Editor', id: 'editor'},
            ],
            limitList: [5, 10, 20, 50, 100],
            pagination: {
                itemsPerPage: 20
            },
            searchLength: 2,
            searchLimit: 250,
            showPopup: false,
            errorMessage: '',
            editedItem: {},
            defaultActionButtons: [
                {
                    category: 'edit',
                    icon: 'mdi-pencil',
                    color: '',
                    text: 'Edit',
                    clickHandler: this.editItem
                },
                {
                    category: 'delete',
                    icon: 'mdi-delete',
                    color: 'error',
                    text: 'Delete',
                    clickHandler: this.deleteItem
                },
                {
                    category: 'destroy',
                    icon: 'mdi-delete-forever',
                    color: 'error',
                    text: 'Delete Forever',
                    clickHandler: this.destroyItem
                },
            ],
            searchTerm: '',
            showPrepaymentConfirm: false
        }
    },
}